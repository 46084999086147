import lowestPrice from "@/lib/data-service/haolv-default/consumer_flight_search_lowestPrice";
import NewFullCalendar from "@/page/admin/travel-book/flight/component/newFullCalendar/flight-calendar";
import moment from "moment";
export default {
    name: "NewCalendar",
    data () {
        return {
            weekBtnStatus: true,
            weekList: [],
            calendarStatus: false,
            show: true,
            weekListObject: {
                0: '周日',
                1: '周一',
                2: '周二',
                3: '周三',
                4: '周四',
                5: '周五',
                6: '周六',
            },

            fullCalendarShow: false,
            airData: {},
            events: [],

            initStatus: 0,
        }
    },
    props: {
        typePage: {
            type: Boolean,
            default: false
        },
        saveWeek: String,
    },
    components: {
        NewFullCalendar
    },
    created () {
        window.addEventListener("click", this.cutShow);
    },
    mounted () {
        //初始刷新日历列表
        this.getDayList();
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
        window.removeEventListener("click", this.cutShow, false);
    },
    watch: {
        saveWeek(newVal) {
            this.getDayList();
        }
    },
    computed: {},
    filters: {},
    methods: {
        getDayList(val) {
            let now = new Date().valueOf();
            let location = this.locationDay(3);
            if (location - now <= 0) {
                this._getWeek();
                this.weekBtnStatu = false;
            } else {
                this._getWeek(location);
                this.weekBtnStatu = true;
            }
            this.typePage ? this.getPrice() : "";
        },
        locationDay(val) {
            return Date.parse(this.saveWeek) - val * Math.floor(24 * 3600 * 1000);
        },
        _getWeek(val) {
            let arr = [];
            val ? val : "";
            for (let i = 0; i < 7; i++) {
                let dateTime = moment(val).subtract(-i, "days").format("YYYY-MM-DD");
                let week = moment(val).subtract(-i, "days")._d;
                week = week.getDay();
                week = this.weekListObject[week]
                let obj = {
                    week: week,
                    date: dateTime,
                    status: false
                };
                arr.push(obj);
            }
            this.weekList = arr;
            // if (this.saveWeek) {
            arr.map((item, index) => {
                item.date === this.saveWeek ? (item.status = true) : "";
            });
            // }
            this.setPrice();
        },
        setPrice() {
            let weekList = JSON.parse(JSON.stringify(this.weekList))
            weekList.map(item => {
                if (this.events) {
                    this.events.map(day => {
                        if (item.date === day.depDate) {
                            item.price = day.ticketPrice;
                        }
                    });
                }
            });
            this.weekList = weekList
        },
        getPrice() {
            let formData = this.$parent.formData;
            if (!formData.arrCityPort) return;
            let endTime = new Date().valueOf() + 24 * 3600 * 1000 * 29;
            if (!formData.arrCityPort.code || formData.arrCityPort.code === '') {
                return
            }
            if (!formData.depCityPort.code || formData.depCityPort.code === '') {
                return
            }
            let data = {
                arrCode: formData.arrCityPort.code,
                depCode: formData.depCityPort.code,
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment(endTime).format("YYYY-MM-DD"),
                supplierType: 1
            };
            lowestPrice(data).then(res => {
                console.log(res);
                this.events = res.result || [];
                this.setPrice();
            });
        },
        leftTime(val) {
            let _this = this;
            if (val === "next") {
                this.dayShow();
                this._getWeek(this.weekList[6].date);
                this.weekBtnStatus = true;
            } else if (val === "last") {
                if (!this.weekBtnStatus) return;
                this.dayShow();
                let date = moment(this.weekList[0].date).subtract("days", 6).format("YYYY-MM-DD");
                let now = moment().format("YYYY-MM-DD");
                let dayNum = Date.parse(date) - Date.parse(now);
                if (dayNum <= 0) {
                    this._getWeek(now);
                } else {
                    this._getWeek(date);
                }
                if (date.valueOf() <= now.valueOf()) {
                    this.weekBtnStatus = false;
                }
            }
        },
        dayShow() {
            this.show = false;
            setTimeout(() => {
                this.show = true;
            });
        },
        setTime(val) {
            this.weekList.map(item => {
                item.status = false;
            });
            val.status = true;
            this.$emit("update:saveWeek", val.date);
            this.$emit("clickDate", val.date);
        },
        cutShow(e) {
            if (!this.calendarStatus && this.fullCalendarShow) {
                this.fullCalendarShow = false;
            }
            console.log(e.target.id)
            if (['init', 'init-icon', 'init-p'].indexOf(e.target.id) > -1) {
                this.fullCalendarShow = true;
                // 获取最低价格
            }
        },
        dayClick(val) {
            let date1 = new Date(val.date).valueOf();
            let date = new Date().valueOf();
            if (date1 < date) return;
            this.fullCalendarShow = false;
            this.$emit("update:saveWeek", val.date.format("YYYY-MM-DD"));
            this.$emit("clickDate", val.date.format("YYYY-MM-DD"));
            this.$nextTick(() => {
                this.getDayList();
            });
        },
        changeMonth(startTime, endTime, firstDay) {
            let formData = this.$parent.formData;
            if (!formData.arrCityPort) return;
            let nowDate = new Date(moment().format("YYYY-MM-DD 00:00:00")).valueOf();
            let clickMonth = new Date(firstDay).valueOf();
            let endDay = moment(firstDay).valueOf() + 24 * 3600 * 1000 * 29
            if (!formData.arrCityPort.code || formData.arrCityPort.code === '') {
                return
            }
            if (!formData.depCityPort.code || formData.depCityPort.code === '') {
                return
            }
            let data = {
                arrCode: formData.arrCityPort.code,
                depCode: formData.depCityPort.code,
                startDate: moment(clickMonth >= nowDate ? firstDay : nowDate).format("YYYY-MM-DD"),
                endDate: moment(endDay).format("YYYY-MM-DD"),
                supplierType: 1
            };
            lowestPrice(data).then(res => {
                console.log(res);
                this.events = this.events.concat(res.result);
            });
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
