import ChangClause from "@/page/admin/travel-book/flight/component/changClause";
import consumer_flight_search_stipulatesRule from '@/lib/data-service/haolv-default/consumer_flight_search_stipulatesRule'
import ChangeClausePopup from '@/page/admin/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
export default {
    data() {
        return {
            ruleShow: false, // 退改规则弹窗
            ruleText: {},
            ruleLoading: false,
        };
    },
    props: {
        detail: Array,
        type: String,
        ticketDetail: Object
    },
    components: {
        ChangClause,
        ChangeClausePopup
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        //退改规则
        backRule(val, index) {
            this.ruleShow = true
            this.ruleLoading = true
            this.ruleText = {}
            let params = {
                rqData: {
                    cabinCode: val.cabin || val.cabinCode,
                    flightKey: val.flightKey,
                    flightNo: val.flightNo,
                    policyId: val.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        noShow() {
            this.changeShow = null;
        }
    }
}