import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue";
import NewCalendar from '@/page/admin/travel-book/flight/component/newCalendar/index.vue'
import DomesticTicketAirlineSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airline-selector/1.0.0/index.vue';
import moment from "moment";
import consumer_flight_search_airlines from '@/lib/data-service/haolv-default/consumer_flight_search_airlines';
import NewLuggageClause from "@/page/admin/travel-book/flight/component/newLuggageClause/index.vue";
import consumer_air_ticket_fligthLuggage from '@/lib/data-service/haolv-default/consumer_air_ticket_fligthLuggage'
import NewChangeClause from "@/page/admin/travel-book/flight/component/newChangeClause/index.vue";
import stipulates from '@/lib/data-service/haolv-default/consumer_flight_search_stipulates'
import consumer_trains_order_orderScheduledCheck from '@/lib/data-service/haolv-default/consumer_trains_order_orderScheduledCheck' // 校验超标
//import CheckTravel from '@/component/checkTravel/index.vue'
import CheckTravel from '@/component/checkTravel/import_latest_version_module'
//import checkExcess from '@/lib/data-service/haolv-default/consumer_flight_order_checkExcess'
import consumer_flight_order_newCheckExcess from '@/lib/data-service/haolv-default/consumer_flight_order_newCheckExcess'
//import budgetOrder from '@/lib/data-service/haolv-default/consumer_flight_order_budgetOrder'
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import consumer_web_policyConfig_travelPolicyTips from '@/lib/data-service/haolv-default/consumer_web_policyConfig_travelPolicyTips' // 差标提示
import consumer_apply_myApplyList from '@/lib/data-service/haolv-default/consumer_apply_myApplyList' // 我的出差
import TravelPolicyTips from '@/page/admin/travel-book/flight/search/component/travelPolicyTips/index.vue'
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop'
import FlightListSkeleton from '@/component/skeleton/flight-list-skeleton/index.vue'
import consumer_flight_search_flightPrice from '@/lib/data-service/haolv-default/consumer_flight_search_flightPrice'

import consumer_flight_search_changeSearch from '@/lib/data-service/haolv-default/consumer_flight_search_changeSearch'

import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import ChangeClausePopup from '@/page/admin/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'

export default {
    data () {
        return {
            // evectionNo: '',
            // evectionType: '',
            userInfo: {}, // 个人信息
            evectionParams: {},
            formData: {
                flightType: 1,
                depDate: '',
                comeBackDate: '',
                depCityPort: {},
                arrCityPort: {},
                orderField: '', // 排序字段，depTime-起飞时间 arrTime-到达时间。basePrice-价格排序 默认按起飞时间
                orderType: '', // 排序类型，asc-从小到大/从早到晚/从低到高 desc-从大到小/从晚到早/从高到低。默认asc
            },
            ticketAirlineSelector: null,
            params: {},//预定航班信息

            directFlight: 0, // 是否直飞 0=不限 1=是 2=其它
            psgType: 1, //  乘机人类型

            saveWeek: '', //日历active
            startPickerOptions: {
                disabledDate: time => {
                    return time.getTime() < Date.now() - 1000 * 3600 * 24;
                }
            },
            endPickerOptions: {
                disabledDate: time => {
                    if (this.formData.depDate) {
                        return time.getTime() < new Date(this.formData.depDate).valueOf();
                    } else {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    }
                }
            },
            airlinesList: [],
            airlinesParams: '',
            classTypeList: [
                //  舱位列表
                /*{
                    value: 0,
                    label: "不限"
                },*/
                {
                    value: '1,2',
                    label: "头等/商务舱"
                },
                /*{
                    value: 2,
                    label: "公务舱"
                },*/
                {
                    value: '3',
                    label: "经济舱"
                }
            ],
            cabinParams: '',
            takeOffTime: [
                /*{ value: "", label: "全部" },*/
                { value: "00:00-05:59", label: "00:00-05:59" },
                { value: "06:00-11:59", label: "06:00-11:59" },
                { value: "12:00-17:59", label: "12:00-17:59" },
                { value: "18:00-23:59", label: "18:00-23:59" }
            ],
            takeOffTimeParams: '',
            planeSizeList: [
                {value: 1, label: '大型机'},
                {value: 2, label: '中型机'},
                {value: 3, label: '其他型机'},
            ],
            planeSizeParams: '',
            depAirportList: [],
            depAirportParams: '', // 出发城市机场搜索关键词
            arrAirportList: [],
            arrAirportParams: '',


            airCompanyVisible: false,

            oriFlightsList: [],
            filterFlightList: [], // 中间存储列表
            flightsList: [],

            flightReturnList: [], // 返程航班
            flightReturnIndex: 0, // 往返的当前选择index
            currentPolicyItem: {}, // 暂存选中的policyItem信息
            currentItem: {}, // 暂存选中的航班信息
            returnCheckIndex: 0, // 往返审核的时候的顺序

            loadingShow: true,
            percent: 0,
            percentSetInterval: '',

            canJourney: true,
            journeyNo: '',

            // 出差信息
            evectionInfo: {},
            trainList: [],
            flightList: [],
            hotelList: [],
            travelUserList: [],

            travelPolicyTips: [],
            popoverLoading: false,
            myTravelPolicy: {},



            dialogVisible: false,
            canSelectTravelUserList: [], // 可以选的出行人（出差单里的）
            // popSelectTravelUserList: [],
            travelUserCheckList: [],
            // newTravelUser: [],
            pendingTravelUserList: [], // 待定的出行人列表

            hotelUserVisible: false,
            hotelForm: {
                roomNum: 0,
                checkInPerson: []
            },
            hotelRules: {},
            hotelUserList: [],
            loades: 10,

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',

            ruleShow: false, // 退改规则弹窗
            ruleText: {},
            ruleLoading: false,
        }
    },
    props: {
        flightType: {
            type: String
        },
        changeForm: {
            type: Object
        },
        segmentList: {
            type: Object
        },
        evectionNo: {
            type: String
        },
        changeOrderNo: {
            type: String
        },
        changeType: {
            type: Number
        },
        evectionType: {
            type: Number
        },
        newTravelUser: {
            type: Array,
            default: () => []
        },
        changeSupplierOrderNo: {
            type: String
        }
    },
    components: {
        CitySelector,
        NewCalendar,
        DomesticTicketAirlineSelector,
        NewLuggageClause,
        NewChangeClause,
        CheckTravel,
        TravelPolicyTips,
        FlightListSkeleton,
        ChangeClausePopup
    },
    created () {},
    async mounted () {
        //搜索航班
        // let query = this.$route.query
        // this.evectionNo = query.evectionNo
       //  this.evectionType = parseInt(query.evectionType)
        /*let obj = {
            flightType: Number(query.flightType) || 1,//航程类型
            depCityPort: {
                name: query.fromStationName,
                code: query.fromStationCode
            },
            arrCityPort: {
                name: query.toStationName,
                code: query.toStationCode
            },
            depDate: query.startDate || moment(new Date()).format('YYYY-MM-DD'),//去程
            comeBackDate: query.endDate || '',//返程
        }
        this.formData = Object.assign(this.formData, obj)
        this.saveWeek = obj.depDate*/
        this.formData = Object.assign(this.formData, this.changeForm)
        this.saveWeek = this.formData.depDate
        this.reactSearch()
        // this.getAirlines()
        //this.initCheckTravelBox() // 初始化单程的审核

        // if (this.evectionType === 1) {
        //     return
        // }
        // // 如果是因公出差
        // this.getTravelInfoAndUserList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        ticketAirlineSelector(newVal, oldVal) {
            console.log(newVal, oldVal)
        },
    },
    computed: {},
    filters: {
        filterPercent(val) {
            if (val < 16) {
                return '航班信息准备中...'
            } else if (val >= 16 && val < 36) {
                return '机票舱位准备中...'
            } else if (val >= 36 && val < 56) {
                return '机票坐席准备中...'
            } else if (val >= 56 && val < 80) {
                return '机票折扣准备中...'
            } else if (val >= 80 && val < 100) {
                return '低价机票准备中...'
            } else {
                return ''
            }
        },
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        },
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        async getUserInfo() {
            this.loading = true
            let [err, res] = await awaitWrap(get_user_info())
            this.loading = false
            this.userInfo = res.datas

            console.log(this.userInfo)
        },

        // 获取整体的差标提示
        getTravelPolicyTips() {
            let params = {
                businessType: 1,
                evectionNo: this.evectionNo
            }
            consumer_web_policyConfig_travelPolicyTips(params).then(res => {
                this.travelPolicyTips = res.datas.flightMsg
            })
        },
        // 获取当前出差单信息和可选择的出行人列表
        getTravelInfoAndUserList() {
            let params = {
                evectionNo: this.evectionNo,
                approveType: 1,
            }
            consumer_apply_myApplyList(params).then(res => {
                this.canSelectTravelUserList = res.datas.list[res.datas.list.length - 1].staffList
            })
        },
        getAirlines() {
            consumer_flight_search_airlines({
                currentPage: 1,
                pageSize: 9999,
            }).then(res => {
                let airlinesList = res.pageResult.pageData
                airlinesList.forEach(value => {
                    value.label = value.airCompanyShortName
                    value.value = value.airlineCode
                })
                this.airlinesList = airlinesList
            })
        },
        handleCommandAirport(val) {
            this.airlinesParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseAirlines() {
            this.airlinesParams = ''
            //this.search()
            this.trainFilter()
        },
        handleCommandCabin(val) {
            this.cabinParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseCabin() {
            this.cabinParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandTakeOffTime(val) {
            this.takeOffTimeParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseTakeOffTime() {
            this.takeOffTimeParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandPlaneSize(val) {
            this.planeSizeParams = val
            // this.search()
            this.trainFilter()
        },
        handleClosePlaneSize() {
            this.planeSizeParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandDepAirport(val) {
            if (val === '') {
                return
            }
            this.depAirportParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseDepAirport() {
            this.depAirportParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandArrAirport(val) {
            if (val === '') {
                return
            }
            this.arrAirportParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseArrAirport() {
            this.arrAirportParams = ''
            // this.search()
            this.trainFilter()
        },
        clearAllCommandToSearch() {
            this.airlinesParams = ''
            this.cabinParams = ''
            this.takeOffTimeParams = ''
            this.planeSizeParams = ''
            this.depAirportParams = ''
            this.arrAirportParams = ''
            // this.search()
            this.trainFilter()
        },
        clearAllCommand() {
            this.airlinesParams = ''
            this.cabinParams = ''
            this.takeOffTimeParams = ''
            this.planeSizeParams = ''
            this.depAirportParams = ''
            this.arrAirportParams = ''
        },
        setFlightReturnList() {
            this.flightReturnList = []
            if (this.formData.flightType === 2) {
                let deptJourney = {
                    depCityName: this.formData.depCityPort.name,
                    depCityCode: this.formData.depCityPort.code,
                    arrCityName: this.formData.arrCityPort.name,
                    arrCityCode: this.formData.arrCityPort.code,
                    depDate: this.formData.depDate
                }
                let arrJourney = {
                    depCityName: this.formData.arrCityPort.name,
                    depCityCode: this.formData.arrCityPort.code,
                    arrCityName: this.formData.depCityPort.name,
                    arrCityCode: this.formData.depCityPort.code,
                    depDate: this.formData.comeBackDate
                }
                this.flightReturnList.push(deptJourney)
                this.flightReturnList.push(arrJourney)
            }
        },
        clickDate(val) {
            this.flightReturnIndex === 0 ? this.formData.depDate = val : this.formData.comeBackDate = val
            this.search();
        },
        changeDirectFlight(val) {
            this.search()
        },
        changeFormAndTo() {
            let depCityPort = JSON.parse(JSON.stringify(this.formData.depCityPort))
            let arrCityPort = JSON.parse(JSON.stringify(this.formData.arrCityPort))
            // this.formData.depCityPort = arrCityPort
            // this.formData.arrCityPort = depCityPort
            this.$set(this.formData, 'depCityPort', arrCityPort)
            this.$set(this.formData, 'arrCityPort', depCityPort)
            this.reactSearch()
        },
        changeFlight(item, index) {
            // 清空所有搜索条件
            this.clearAllCommand()
            this.flightReturnIndex = index
            if (index === 0) {
                let deptJourney = {
                    depCityName: this.formData.depCityPort.name,
                    depCityCode: this.formData.depCityPort.code,
                    arrCityName: this.formData.arrCityPort.name,
                    arrCityCode: this.formData.arrCityPort.code,
                    depDate: this.formData.depDate
                }
                this.flightReturnList[index] = deptJourney
            } else {
                let arrJourney = {
                    depCityName: this.formData.arrCityPort.name,
                    depCityCode: this.formData.arrCityPort.code,
                    arrCityName: this.formData.depCityPort.name,
                    arrCityCode: this.formData.depCityPort.code,
                    depDate: this.formData.comeBackDate
                }
                this.flightReturnList[index] = arrJourney
            }

            this.search()

        },
        sortSwitch(orderField) {
            if (this.formData.orderField === orderField) {
                // 如果本来就是起飞时间排序
                let sortArr = ['asc', 'desc', '']
                let sortIndex = sortArr.indexOf(this.formData.orderType)
                sortIndex === 2 ? sortIndex = 0 : sortIndex++
                this.formData.orderType = sortArr[sortIndex]
            } else {
                // 如果不是起飞时间排序
                this.formData.orderField = orderField
                this.formData.orderType = 'asc'
            }
            this.sortList()
        },
        reactSearch() {
            this.dialogErrorVisible = false
            this.dialogError2Visible = false
            // 清空所有搜索条件
            this.clearAllCommand()
            // 重构往返订单的结果列表
            this.setFlightReturnList()
            this.search()

            this.$refs['newCalendar'].getDayList()
        },
        search() {
            let formData = this.formData

            let detection = (obj, rule) => {
                let state = {
                    rule: rule || ['depDate'],
                    inputRule: ['depCityPort', 'arrCityPort'],
                    status: false
                }
                state.status = state.rule.some((name, index) => {
                    if (!obj[state.inputRule[index]]) {
                        if (this.$refs[state.inputRule[index]]) {
                            this.$refs[state.inputRule[index]].$refs.aBaseEntitySelector.$refs.aInput.$refs.input.click()
                        }
                        return true
                    }
                    if (!obj[name]) {
                        this.$refs[name] ? this.$refs[name].focus() : ''
                        return true
                    }
                })
                return state.status
            }

            if (this.formData.flightType === 1) {
                if (detection(formData)) return
            } else {
                let arr = ['depDate', 'comeBackDate']
                if (detection(formData, arr)) return
            }

            this.flightReturnIndex === 0 ? this.saveWeek = this.formData.depDate : this.saveWeek = this.formData.comeBackDate

            // 获取出发城市机场的三字码
            let depCityPortCode = ''
            if (this.depAirportParams!== '') { // 如果有搜索关键词
                depCityPortCode = this.depAirportParams.value
            } else {
                // 没有搜索关键词
                depCityPortCode = this.flightReturnIndex === 0 ? this.formData.depCityPort.code : this.formData.arrCityPort.code
            }
            // 获取到达地城市机场的三字码
            let arrCityPortCode = ''
            if (this.arrAirportParams !== '') {
                arrCityPortCode = this.arrAirportParams.value
            } else {
                arrCityPortCode = this.flightReturnIndex === 0 ? this.formData.arrCityPort.code : this.formData.depCityPort.code
            }

            let params = {
                depDate: this.saveWeek,
                flightNo: formData.flightNo,
                cabinRank: formData.cabinRank,
                orderNo: this.changeOrderNo,
                segmentNo: this.formData.segmentNo,
                userIds: this.newTravelUser,
            };

            let data = {
                companyCode: '',
                rqData: Object.assign({}, params),
                //sign: '',
                //timeStamp: ''
            }
            this.getFlights(data);
        },
        // 获取航班列表
        getFlights(params) {
            this.percent = 0
            this.loadingShow = true
            /*this.percentSetInterval = setInterval(() => {
                if (this.percent < 95) {
                    this.percent++
                }
                if (this.percent === 100) {
                    this.loadingShow = false
                    clearInterval(this.percentSetInterval)
                }
            }, 100)*/
            this.flightsList = [];
            consumer_flight_search_changeSearch(params).then(data => {
                // this.percent = 100
                if (!data.rsData) {
                    this.loadingShow = false
                    return
                }
                let flightsList = data.rsData
                let airlinesCodeList = [] // 航司Code合集
                let airlinesList = [] // 航司合集
                let depAirportCodeList = [] // 起飞机场的code合集
                let depAirportList = [] // 起飞机场的合集
                let arrAirportCodeList = [] // 降落机场的code合集
                let arrAirportList = [] // 降落机场的合集
                flightsList.forEach((value, index) => {
                    value.show = 0
                    value.flightsShow = []
                    value.price = 0
                    value.discount = 0
                    value.cabin = ''
                    value.loading = false
                    //let productTypeTit = [] // 收入tit
                    //let productList = []
                    value.policys.forEach((value1, index1) => {
                        if (index1 === 0) {
                            value.price = value1.priceDatas[0].settlement
                            value.discount = value1.cabin[0].discount
                            value.cabin = value1.cabin[0].cabinName
                        } else {
                            if (value.price > value1.priceDatas[0].settlement) {
                                value.price = value1.priceDatas[0].settlement
                                value.discount = value1.cabin[0].discount
                                value.cabin = value1.cabin[0].cabinName
                            }
                        }
                        /*let titIndex = productTypeTit.indexOf(value1.productTypeTxt)
                        if (titIndex === -1) {
                            productTypeTit.push(value1.productTypeTxt)
                            let params = {
                                productTypeTxt: value1.productTypeTxt,
                                productList: [value1]
                            }
                            productList.push(params)
                        } else {
                            productList[titIndex].productList.push(value1)
                        }*/
                    })
                    //value.productList = productList

                    if (airlinesCodeList.indexOf(value.airCompanyCode) === -1) {
                        airlinesCodeList.push(value.airCompanyCode)
                        airlinesList.push({
                            value: value.airCompanyCode,
                            label: value.airCompanyName
                        })
                    }
                    if (depAirportCodeList.indexOf(value.depAirportCode) === -1) {
                        depAirportCodeList.push(value.depAirportCode)
                        depAirportList.push({
                            value: value.depAirportCode,
                            label: value.depAirportName
                        })
                    }
                    if (arrAirportCodeList.indexOf(value.arrAirportCode) === -1) {
                        arrAirportCodeList.push(value.arrAirportCode)
                        arrAirportList.push({
                            value: value.arrAirportCode,
                            label: value.arrAirportName
                        })
                    }
                })
                this.airlinesList = airlinesList
                this.depAirportList = depAirportList
                this.arrAirportList = arrAirportList
                this.oriFlightsList = flightsList
                // this.flightsList = flightsList
                console.log(this.flightsList)
                this.trainFilter()
                // this.sortList()
                // this.creditState = data.creditState;
            }).catch(err => {
                this.loadingShow = false
                clearInterval(this.percentSetInterval)
            });
        },
        trainFilter() {
            let oriFlightsList = JSON.parse(JSON.stringify(this.oriFlightsList))

            if (this.airlinesParams !== '') {
                // this.airlinesParams.value
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.airCompanyCode === this.airlinesParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.cabinParams !== '') {
                let flightItemList = []
                let cabinParamsArr = this.cabinParams.value.split(',')
                oriFlightsList.forEach(value => {
                    let policys = []
                    value.productList.forEach(value1 => {
                        let product = []
                        value1.productList.forEach(value2 => {
                            if (cabinParamsArr.indexOf(value2.cabin[0].cabinRank.toString()) >= 0) {
                                product.push(value2)
                            }
                        })
                        value1.productList = product
                        if (product.length > 0) {
                            policys.push(value1)
                        }
                    })
                    value.productList = policys
                    if (policys.length > 0) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.takeOffTimeParams !== '') {
                let flightItemList = []
                let takeOffTimeArr = this.takeOffTimeParams.value.split('-')
                let leftTime = this.timeToMinute(takeOffTimeArr[0])
                let rightTime = this.timeToMinute(takeOffTimeArr[1])
                oriFlightsList.forEach(value => {
                    let depTime = this.timeToMinute(value.depTime)
                    if (depTime >= leftTime && depTime <= rightTime) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.planeSizeParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.planeSize === this.planeSizeParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.depAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.depAirportCode === this.depAirportParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.arrAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.arrAirportCode === this.arrAirportParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            this.filterFlightList = oriFlightsList
            this.sortList()
        },
        timeToMinute(val) {
            let timeVal = val.split(':')
            let hour = parseInt(timeVal[0]) * 60
            let minute = parseInt(timeVal[1])
            return hour + minute
        },
        sortList() {
            let flightList = JSON.parse(JSON.stringify(this.filterFlightList))

            let timeToMinute = function (val) {
                let timeVal = val.split(':')
                let hour = parseInt(timeVal[0]) * 60
                let minute = parseInt(timeVal[1])
                return hour + minute
            }
            // 按出发时间正序排列
            let depTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按出发时间反序排列
            let depTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按到达时间正序排列
            let arrTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按到达时间反序排列
            let arrTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按价格正序排列
            let priceCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return -1;
                } else if (xPrice > yPrice) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按价格反序排序
            let priceReverseCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return 1;
                } else if (xPrice > yPrice) {
                    return -1;
                } else {
                    return 0;
                }
            }

            if (this.formData.orderField === 'depTime') {
                if (this.formData.orderType === 'asc') {
                    flightList.sort(depTimeCompare)
                } else if (this.formData.orderType === 'desc') {
                    flightList.sort(depTimeReverseCompare)
                }
            } else if (this.formData.orderField === 'arrTime') {
                if (this.formData.orderType === 'asc') {
                    flightList.sort(arrTimeCompare)
                } else if (this.formData.orderType === 'desc') {
                    flightList.sort(arrTimeReverseCompare)
                }
            } else if (this.formData.orderField === 'basePrice') {
                if (this.formData.orderType === 'asc') {
                    flightList.sort(priceCompare)
                } else if (this.formData.orderType === 'desc') {
                    flightList.sort(priceReverseCompare)
                }
            }
            this.loadingShow = false
            this.flightsList = flightList
        },
        toSearchAgain(item, index) {
            // 先清除搜索关键字
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        showChangeClause(policyItem, item) {
            this.ruleShow = true
            this.ruleLoading = true
            this.ruleText = {}
            let params = {
                rqData: {
                    cabinCode: policyItem.cabin[0].cabinCode,
                    flightKey: item.flightKey,
                    flightNo: item.flightNo,
                    policyId: policyItem.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        showLuggageClause(policyItem, item) {
            console.log(policyItem, item)
            this.luggageParams = {}
            let data = {
                rqData: {
                    "arrCityPortCode": item.arrCityCode,
                    "cabinCode": policyItem.cabin[0].cabinCode,
                    "depCityPortCode": item.depCityCode,
                    "depDate": item.depDate,
                    "flightNo": item.flightNo,
                    "flightProductType": policyItem.flightProductType,
                    "flightType": this.formData.flightType,
                    "psgType": this.psgType,
                    businessExt: policyItem.businessExt
                }
            };
            this.luggageLoading = true
            consumer_air_ticket_fligthLuggage(data).then(res => {
                this.luggageLoading = false
                if (res.datas && res.datas.rsData) {
                    let rsData = res.datas.rsData
                    this.luggageParams = {
                        flyFree: rsData.flyFree,
                        freeWithYou: rsData.freeWithYou,
                        rests: rsData.rests,
                    }
                }
            }).catch(() => {
                this.luggageLoading = false
            })
        },
        async journeyType(policyItem, item) {
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            let cabinRank = this.cabinParams.value
            let formData = {
                rqData: {
                    "evectionNo": this.evectionNo,
                    "journeyNo": this.changeOrderNo,
                    cabinRank: cabinRank == 0 ? 1 : cabinRank == 3 ? 3 : 2,
                    directFlight: 0,
                    flightType: this.formData.flightType,
                    psgType: this.psgType,
                    segmentList: []
                }
            };
            let segment = {
                "airportTax": policyItem.priceDatas[0].airportTax,
                "billingFee": policyItem.priceDatas[0].billingFee,
                "cabinName": policyItem.cabin[0].cabinName,
                "cabinCode": policyItem.cabin[0].cabinCode,
                "cabinRank": policyItem.cabin[0].cabinRank,
                "chdCabinCode": policyItem.cabin[0].chdCabinCode,
                "comment": policyItem.comment,
                "crewType": policyItem.priceDatas[0].crewType,
                "discount": policyItem.cabin[0].discount,
                "fuelTax": policyItem.priceDatas[0].fuelTax,
                "policyId": policyItem.policyId,
                "price": policyItem.priceDatas[0].price,
                "priceTaxes": policyItem.priceDatas[0].priceTaxes,
                "productType": policyItem.productType,
                "segmentType": 1,//去返程
                "settlement": policyItem.priceDatas[0].settlement,
                "settlementPriceTaxes": policyItem.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policyItem.vtWorkTime,
                "workTime": policyItem.workTime,
                "depTime": item.depTime,
                "flightProductType": policyItem.flightProductType,
                businessExt: policyItem.businessExt,

                "airCompanyCode": item.airCompanyCode,
                "airCompanyName": item.airCompanyName,
                "arrAirportCode": item.arrAirportCode,
                "arrAirportName": item.arrAirportName,
                "arrCityCode": item.arrCityCode,
                "arrCityName": item.arrCityName,
                "arrDate": item.arrDate,
                "arrTerminal": item.arrTerminal,
                "arrTime": item.arrTime,
                "chaRule": item.chaRule,
                "depAirportCode": item.depAirportCode,
                "depAirportName": item.depAirportName,
                "depCityCode": item.depCityCode,
                "depCityName": item.depCityName,
                "depDate": item.depDate,
                "depTerminal": item.depTerminal,
                "directFlight": item.directFlight,
                "duration": item.duration,
                "flightNo": item.flightNo,
                "planeType": item.planeType,
                "refRule": item.refRule,
                "stopNum": item.stopNum,

                policyType: policyItem.policyType,
                hasShare: item.hasShare,
                isShare: item.hasShare ? 1 : 0, // 是否共享航班 1-是 0-否"
                sharedFlightNo: item.sharedFlightNo,
                sharedAirCompanyCode: item.sharedAirCompanyCode,
                isShareText: `实际乘坐航班：${item.sharedAirCompanyName}${item.sharedFlightNo}请按实际乘坐航班办理值机。`,

                flightKey: item.flightKey
            }
            /**单程 */
            segment.segmentType = 1
            formData.rqData.segmentList.push(Object.assign({}, segment))
            this.params = formData
            console.log(this.params)
            // 因私出差
            // if (this.evectionType === 1) {
            //     this.params.rqData.overproofApplyList = []
            //     this.reserveOrder()
            // } else {
            //     this.$refs.checkTravelBox.run()
            // }
            this.params.rqData.overproofApplyList = []
            this.reserveOrder()
        },
        initCheckTravelBox() {
            let loading = '';
            this.$refs.checkTravelBox.init({
                checkFun: () => {
                    let formData = this.formData
                    let params = {
                        // 航程类型
                        flightType: formData.flightType,
                        needPnr: 0,
                        // 出发城市三字码。
                        depCityPortCode: this.flightReturnList.length === 0 ? formData.depCityPort.code : formData.arrCityPort.code,
                        // 到达城市三字码。
                        arrCityPortCode: this.flightReturnList.length === 0 ? formData.arrCityPort.code : formData.depCityPort.code,
                        // 出发日期
                        depDate: this.saveWeek,
                        // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
                        cabinRank: formData.cabinRank,
                        // 航空公司二字码
                        airLineCode: formData.airLineCode,
                        // psgType
                        psgType: this.psgType,
                        //  是否直飞
                        directFlight: 0,
                        // 折扣
                        discount: 0,
                        orderField: "",
                        takeOffInterval: formData.takeOffInterval,
                        // airLineCode: formData.airLineCode ? formData.airLineCode : '',
                        flightNo: formData.flightNo ? formData.flightNo : ''
                    };
                    let checkSegment = {
                        airLineCode: this.params.rqData.segmentList[0].airCompanyCode,
                        arrCityPortCode: this.params.rqData.segmentList[0].arrAirportCode,
                        cabinRank: this.params.rqData.segmentList[0].cabinRank,
                        depCityPortCode: this.params.rqData.segmentList[0].depAirportCode,
                        depDate: this.params.rqData.segmentList[0].depDate,
                        directFlight: 1,
                        discount: this.params.rqData.segmentList[0].discount,
                        flightNo: this.params.rqData.segmentList[0].flightNo,
                        flightType: this.params.rqData.segmentList[0].flightProductType,
                        psgType: 1,
                        orderField: '',
                        orderType: '',
                        takeOffInterval: '',
                    }
                    let data = {
                        "bottomPrice": "",
                        "cabinName": this.params.rqData.segmentList[0].cabinName,
                        "cabinRank": this.params.rqData.segmentList[0].cabinRank,
                        "depDate": this.formData.depDate,
                        "discount": this.params.rqData.segmentList[0].discount,
                        "evectionNo": this.evectionNo || this.changeEvectionNo,
                        "rqData": Object.assign({}, params),
                        "price": this.params.rqData.segmentList[0].settlement,
                        "depTime": this.params.rqData.segmentList[0].depTime
                    }
                    loading = this.$loading({
                        lock: true,
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    return consumer_flight_order_newCheckExcess(data).then(res => {
                        let violationResults = res.datas.violationResults;
                        loading.close();
                        return violationResults // 返回一个数组给组件
                        /* 数组每项包含以下内容
                        * businessType: 业务类型:1.火车票;2.机票;3.酒店 integer(int32)
                        * controlRules: 管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定,4不限 integer(int32)
                        * seatName: 差标政策 string
                        * standard: 差标类型 string
                        * userId: 违反用户id integer(int64)
                        * userRealName 违反用户名字 string
                        * violationOfReason 预订信息 string
                        * */
                    }).catch(() => {
                        loading.close();
                        return null
                    })
                },
                reserveSubmitFun: (warningList) => { // 返回的warningList数组里面的每一项会多出一个reason的字段，是存储选中的超标原因
                    this.params.rqData.overproofApplyList = warningList

                    return new Promise((resolve, reject) => {
                        resolve()
                    })
                },
                reserveResultFun: (result) => { // 返回evectionNo
                    this.canJourney = true
                    let obj = {
                        violationResults: this.params.rqData.overproofApplyList,
                        params: this.params
                    }
                    this.$emit('getSegment', obj)
                    this.canJourney = true
                },
                // 取消弹窗返回的方法
                cancelFun: () => {
                    this.canJourney = true
                }
            })
        },
        reserveOrder() {
            let obj = {
                violationResults: [],
                params: this.params
            }
            this.$emit('getSegment', obj)
            this.canJourney = true
        },

        goReservePage() {
            this.$router.push({
                name: 'admin-travel-reserve',
                query: {
                    evectionNo: this.$route.query.evectionNo,
                    redirectType: '001'
                }
            })
        },



        getStopMsg(val, index) {
            console.log(val, index)
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.flightNo
                }
            }
            // val.stopLoad = true
            this.flightsList[index].stopLoad = true
            let flightsList = JSON.parse(JSON.stringify(this.flightsList))
            consumer_flight_order_flightsStop(params).then(res => {
                flightsList[index].stopResponseData = res.datas
                flightsList[index].stopLoad = false
                this.flightsList = flightsList
            }).catch(() => {
                flightsList[index].stopLoad = false
                this.flightsList = flightsList
            })
        },
        reloadItem(item, index) {
            item.show === 0 ? item.show = 1 : item.show = 0
            return;
            if (item.policys && item.policys.length > 0) {
                return;
            }
            item.loading = true
            let params = {
                "rqData": {
                    "arrAirportCode": item.arrAirportCode,
                    "businessExt": item.businessExt,
                    "deptAirportCode": item.depAirportCode,
                    "deptDate":item.depDate,
                    "deptTime": item.depTime,
                    "flightNo": item.flightNo,
                    "psgType": this.psgType,
                    "shareFlightNo": item.sharedFlightNo,
                },
                depCityPortCode: item.depCityCode,
                arrCityPortCode: item.arrCityCode,
                basePrice: item.basePrice
            }
            consumer_flight_search_flightPrice(params).then(res => {
                let productTypeTit = [] // 收入tit
                let productList = []
                let data = res.rsData
                if (res.rsData.settlement !== null) {
                    item.basePrice = res.rsData.settlement
                }
                if (res.rsData.accord !== null) {
                    item.accord = res.rsData.accord
                }
                /*list.forEach((value1, index1) => {
                    if (index1 === 0) {
                        item.basePrice = value1.priceDatas[0].settlement
                        item.discount = value1.cabin[0].discount
                        item.cabinName = value1.cabin[0].cabinName
                    } else {
                        if (item.basePrice > value1.priceDatas[0].settlement) {
                            item.price = value1.priceDatas[0].settlement
                            item.discount = value1.cabin[0].discount
                            item.cabinName = value1.cabin[0].cabinName
                        }
                    }
                    let titIndex = productTypeTit.indexOf(value1.productTypeTxt)
                    if (titIndex === -1) {
                        productTypeTit.push(value1.productTypeTxt)
                        let params = {
                            productTypeTxt: value1.productTypeTxt,
                            productList: [value1],
                            sortNum: value1.productType === 'QCYX' ? 0 : 1
                        }
                        productList.push(params)
                    } else {
                        productList[titIndex].productList.push(value1)
                    }
                })
                productList.sort((val1, val2) => {
                    return val1.sortNum - val2.sortNum
                })*/
                if (data.flightPolicyList && data.flightPolicyList.length > 0) {
                    productList.push(data.flightPolicyList)
                }
                if (data.policys && data.policys.length > 0) {
                    productList.push(data.policys)
                }
                item.loading = false
                item.policys = [...data.flightPolicyList, ...data.policys]
                item.productList = productList
            }, error => {
                item.loading = false
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage ? error.data.rsMessage : error.data.msg;
                if (errorCode === '090002') {
                    this.dialogErrorVisible = true;
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.reactSearch()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    this.dialogError2Visible = true
                }
            }).catch(() => {
                item.loading = false
            })
        },
        setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13', '9', 'OPL9', 'SGL1','OPL6','OPL11','OPL18','OPL17','OPL20','OPL16','OPL26','OPL23','OPL30','60','FD','NFD','VARI']
            const lprList = ['41','42','43','44','45','26','YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL1','NSTD','62']
            const owList = ['21','22','23','24','25','6','CXF1','WS']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type2'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
